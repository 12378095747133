.footer {
  background-color: #6ba5db;
  background-image: linear-gradient(135deg, #6ba5db 0%, #4a90e2 100%);
  color: #fff;
  overflow-x: hidden;
  position: relative;
  width: 100%; /* Ensure full width */
}

.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(to right, #ff6b6b, #feca57, #48dbfb, #ff9ff3);
}

.footer-container {
  display: flex;
  flex-direction: column;
  max-width: 1200rem;
  margin: 0 auto;
  padding: 0 20px; /* Add horizontal padding */
}

.footer-logo {
  margin-bottom: 30px; /* Reduced margin */
  text-align: center;
}

.footer-logo img {
  width: 100%;
  max-width: 100px; /* Slightly reduced max-width */
  height: auto;
  object-fit: contain;
  filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.1));
  transition: transform 0.3s ease;
}

.footer-logo img:hover {
  transform: scale(1.05);
}

.footer-columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px; /* Reduced gap */
}

.footer-column {
  flex: 1;
  min-width: 180px; /* Slightly reduced min-width */
}

.footer h4 {
  font-size: 1.2em; /* Slightly reduced font size */
  margin-bottom: 15px; /* Reduced margin */
  color: #fff;
  position: relative;
  padding-bottom: 8px; /* Reduced padding */
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer h4::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 35px; /* Slightly reduced width */
  height: 2px; /* Reduced height */
  background-color: #fff;
  transition: width 0.3s ease;
}

.footer-column:hover h4::after {
  width: 50px; /* Reduced hover width */
}

.footer p,
.footer a {
  font-size: 0.9em; /* Slightly reduced font size */
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 10px; /* Reduced margin */
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-block;
}

.footer a:hover {
  color: #fff;
  transform: translateX(5px);
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.4);
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column li {
  margin-bottom: 10px; /* Reduced margin */
}

/* Coming Soon Message */
.coming-soon-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #4a5568;
  color: #ffffff;
  padding: 10px 15px; /* Slightly reduced padding */
  border-radius: 8px;
  font-size: 13px; /* Slightly reduced font size */
  font-weight: bold;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  animation: fadeInOut 2s ease-in-out;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; transform: translateY(20px); }
  10%, 90% { opacity: 1; transform: translateY(0); }
}

/* All Rights Reserved Section */
.footer-bottom {
  text-align: center;
  padding-top: 30px; /* Reduced padding */
  margin-top: 30px; /* Reduced margin */
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.footer-bottom p {
  color: #fff;
  font-size: 13px; /* Slightly reduced font size */
  margin: 0;
  opacity: 0.9;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer {
    padding: 40px 15px 25px; /* Further reduced padding for mobile */
  }

  .footer-container {
    text-align: center;
  }

  .footer-columns {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    width: 100%;
    margin-bottom: 30px; /* Reduced margin */
    padding: 15px; /* Reduced padding */
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px; /* Slightly reduced border radius */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }

  .footer h4 {
    font-size: 1.3em;
  }

  .footer h4::after {
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
  }

  .footer p,
  .footer a {
    font-size: 1em;
  }

  .footer-column ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-column li {
    margin-bottom: 12px;
  }

  .coming-soon-message {
    bottom: 10px;
    right: 10px;
    left: 10px;
    font-size: 12px;
    padding: 8px 12px;
    text-align: center;
  }

  .footer-bottom {
    padding-top: 25px;
    margin-top: 25px;
  }

  .footer-bottom p {
    font-size: 12px;
  }
}

/* Enhanced Mobile Styles */
@media (max-width: 480px) {
  .footer {
    padding: 35px 10px 25px;
  }

  .footer-logo img {
    max-width: 90px;
  }

  .footer h4 {
    font-size: 1.2em;
    margin-bottom: 20px;
  }

  .footer-column {
    padding: 20px 10px;
    margin-bottom: 25px;
  }

  .footer a {
    padding: 8px 0;
  }

  .footer a::after {
    content: '→';
    position: absolute;
    right: -15px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: all 0.3s ease;
  }

  .footer a:hover::after {
    opacity: 1;
    right: -20px;
  }
}