/* Base styling for header */
.header {
  width: 100%;
  height: 80px; /* Set a specific height for the header */
  position: fixed;
  top: 0;
  left: 0;
  background-color: #6ba5db; /* Header background color */
  color: rgb(30 10 10); /* Updated color syntax */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  z-index: 1000;
}

.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.nav-links ul {
  display: flex;
  list-style: none;
  gap: 1.5rem;
}

.nav-links ul li {
  padding: 0.5rem;
}

.nav-links ul li a {
  color: white;
  background-color: transparent;
  padding: 0.5rem 1rem; /* Reduced padding */
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
}

/* Hover effect */
.nav-links ul li a:hover {
  border-bottom: 1px solid #fff; /* Highlight background on hover */
  color: white;
}

/* Active or focused link style */
.nav-links ul li a:active,
.nav-links ul li a:focus,
.active-link {
  border-bottom: 1px solid #fff; /* Highlight background on hover */
}

.header-actions {
  display: flex;
  gap: 1rem;
}

.btn {
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.contact-btn {
  background-color: transparent;
  border: 1px solid #fff;
}

.login-btn {
  background-color: transparent;
}

.signup-btn {
  background-color: #39384a;
  border-radius: 5px;
}

/* Hamburger menu */
.menu-toggle {
  display: none;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.hamburger {
  width: 25px;
  height: 3px;
  background-color: white;
}

/* Mobile styles */
@media (width <= 768px) {
  .nav-links {
    display: none;
  }

  .nav-links.open {
    display: flex;
    flex-direction: column;
    background-color: #1e4056;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    padding: 1rem 0;
  }

  .nav-links ul {
    flex-direction: column;
    gap: 1rem;
  }

  .header-actions {
    display: none;
  }

  .menu-toggle {
    display: flex;
  }
}

/* Desktop styles */
@media (width > 768px) {
  .menu-toggle {
    display: none;
  }

  .nav-links {
    display: flex;
  }

  .header-actions {
    display: flex;
  }
}

/* Logo container */
.logo {
  display: flex;
  align-items: center;
  gap: 10px; /* Adds space between the image and text */
  padding-left: 0; /* Removes padding from the left */
}

/* Image styling - make it circular */
.logo-img {
  width: 100%; /* Adjust the size as needed */
  height: 50px;
  object-fit: contain; /* Ensures the image fills the circle properly */
}

/* Company name styling */
.company-name {
  font-size: 24px;
  font-weight: bold;
  color: #dfcece; /* Adjust the color */
  letter-spacing: 1px; /* Adds slight spacing between letters */
}

.logo-link {
  text-decoration: none;
  color: inherit; /* To ensure the text color stays the same */
}

.logo-link:hover {
  opacity: 0.8; /* Optional hover effect */
}