/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

body {
  color: #333;
  background-color: #f9f9f9;
  padding-top: 30px;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Hero Section */
.hero {
  position: relative;
  background: url("../../assets/Hero.jpg") no-repeat center center / cover;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  width: 100%;
}

.hero-overlay {
  position: absolute;
  inset: 0;
  background-color: rgb(0 0 0 / 50%);
}

.hero-content {
  position: relative;
  z-index: 2;
}

.hero h1 {
  font-size: 4rem;
  font-weight: bold;
  background: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: 2px;
  text-shadow: 2px 4px 10px rgb(0 0 0 / 30%);
  transition: all 0.3s ease-in-out;
}

.hero h1::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 0;
  height: 3px;
  background: #007bff;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.hero h1:hover::after {
  width: 100%;
  left: 0;
}

.hero p {
  font-size: 1.6rem;
  margin: 1.5rem 0;
  color: rgb(255 255 255 / 85%);
  line-height: 1.8;
  letter-spacing: 1px;
  text-shadow: 1px 2px 5px rgb(0 0 0 / 30%);
  transition: all 0.3s ease-in-out;
}

.cta {
  padding: 1rem 2.5rem;
  background: linear-gradient(90deg, #007bff, #00c6ff);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgb(0 123 255 / 30%);
  transition: all 0.3s ease-in-out;
}

.cta:hover {
  background: linear-gradient(90deg, #0056b3, #007bff);
  box-shadow: 0 6px 15px rgb(0 123 255 / 50%);
  transform: translateY(-3px);
}

/* Portfolio Section */
.homehomeportfolio {
  padding: 2rem;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 20px;
}

.homehomeportfolio h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.homehomeportfolio-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.homehomeportfolio-item {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
  overflow: hidden;
  transition: transform 0.3s ease;
  width: calc(33.333% - 1rem);
  max-width: 500px;
}

.homehomeportfolio-item img {
  width: 100%;
  height: auto;
  display: block;
}

.homehomeportfolio-item h4 {
  margin: 1rem 0;
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  background-color: #1f85ad;
  padding: 10px 15px;
  border-radius: 20px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

/* Services Section */
.homeservices {
  padding: 4rem 2rem;
  background-color: #f4f4f4;
  text-align: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.homeservices h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}

.homeservices h2::after {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  background: linear-gradient(90deg, #007bff, #00c6ff);
  margin: 0.5rem auto 0;
}

.homeservice-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.homeservice-item {
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  width: 300px;
  max-width: 100%;
}

.homeservice-item h3 {
  min-height: 70px;
  font-size: 1.8rem;
  color: #007bff;
  margin-bottom: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeservice-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

.homeservice-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgb(0 0 0 / 20%);
}

/* Testimonials Section */
.testimonials {
  padding: 4rem 2rem;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 20px;
}

.testimonials h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}

.testimonials h2::after {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  background: linear-gradient(90deg, #007bff, #00c6ff);
  margin: 0.5rem auto 0;
}

.testimonial-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.testimonial-item {
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
  width: 500px;
  max-width: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  text-align: left;
  color: #333;
  overflow: hidden;
}

.testimonial-item p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 2;
}

.testimonial-item span {
  font-weight: bold;
  color: #007bff;
  font-size: 1rem;
  display: block;
}

.testimonial-item::before {
  content: "\201C"; /* Unicode for left double quotation mark */
  font-size: 5rem;
  color: rgb(0 123 255 / 10%);
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 1;
}

.testimonial-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgb(0 0 0 / 20%);
  background: linear-gradient(135deg, #007bff, #00c6ff);
  color: white;
}

.testimonial-item:hover p,
.testimonial-item:hover span {
  color: white;
}

/* Call to Action Section */
.cta-section {
  padding: 5rem;
  background: radial-gradient(
      circle at 20% 30%,
      rgb(255 255 255 / 20%),
      transparent 90%
    ),
    radial-gradient(
      circle at 80% 70%,
      rgb(255 255 255 / 30%),
      transparent 90%
    ),
    radial-gradient(
      circle at 50% 50%,
      rgb(255 255 255 / 15%),
      transparent 90%
    ),
    radial-gradient(
      circle at 40% 40%,
      rgb(255 255 255 / 5%),
      transparent 90%
    ),
    linear-gradient(
      135deg,
      rgb(77 87 98 / 80%) 40%,
      rgb(107 122 133 / 80%) 90%
    );
  background-blend-mode: overlay;
  text-align: center;
  color: white;
  border-radius: 20px;
  overflow: hidden;
}

.cta-section h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  letter-spacing: 2px;
}

.cta-section .cta {
  padding: 1rem 3rem;
  background: linear-gradient(90deg, #00c6ff, #007bff);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.3rem;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgb(0 123 255 / 30%);
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.cta-section .cta:hover {
  background: linear-gradient(90deg, #0056b3, #007bff);
  box-shadow: 0 6px 15px rgb(0 123 255 / 50%);
  transform: translateY(-3px);
}

/* Our Process Section */
.our-process {
  background: linear-gradient(to bottom right, #f9f9f9, #e0e0e0);
  padding: 60px 30px;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.our-process h2 {
  font-size: 2.8em;
  margin-bottom: 40px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.process-steps {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.process-step {
  background: white;
  padding: 20px;
  border-radius: 16px;
  box-shadow: 0 6px 30px rgb(0 0 0 / 20%);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  width: 20%;
  height: auto;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.process-step:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 30px rgb(0 0 0 / 25%);
}

.process-step h3 {
  min-height: 30px;
  font-size: 1.5em;
  margin-bottom: 10px;
  color: #007bff;
  font-weight: bold;
}

.process-step p {
  font-size: 1em;
  color: #555;
  line-height: 1.5;
  flex-grow: 1;
}

/* Why Choose Us Section */
.why-choose-us {
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 20px;
}

.why-choose-us h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.why-choose-us-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.why-choose-us-item {
  flex: 1 1 300px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  transition: transform 0.3s;
}

.why-choose-us-item:hover {
  transform: translateY(-5px);
}

.why-choose-us-item h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.why-choose-us-item p {
  font-size: 1rem;
  color: #555;
}

/* Responsive Design */
@media (width <= 1200px) {
  .hero h1 {
    font-size: 3.5rem;
  }

  .cta {
    font-size: 1.1rem;
  }
}

@media (width <= 992px) {
  .hero {
    height: 50vh;
  }

  .hero h1 {
    font-size: 3rem;
  }

  .hero p {
    font-size: 1.4rem;
  }

  .cta {
    font-size: 1rem;
    padding: 0.8rem 2rem;
  }

  .testimonial-items {
    gap: 1.5rem;
  }

  .testimonial-item {
    width: 280px;
  }

  .cta-section h2 {
    font-size: 2rem;
  }

  .cta-section .cta {
    font-size: 1.2rem;
    padding: 0.9rem 2.5rem;
  }
}

@media (width <= 768px) {
  .hero {
    
    height: 45vh;
    padding: 0 1rem;
  }

  .hero h1 {
    font-size: 2.5rem;
    letter-spacing: 1px;
  }

  .hero p {
    font-size: 1.2rem;
  }

  .cta {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }

  .homehomeportfolio-item {
    width: calc(50% - 1rem);
  }

  .homeservice-items {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .homeservice-item {
    width: 100%;
  }

  .testimonial-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .testimonial-item {
    width: 100%;
  }

  .cta-section h2 {
    font-size: 1.8rem;
  }

  .cta-section .cta {
    font-size: 1.1rem;
    padding: 0.8rem 2rem;
  }

  .process-steps {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .our-process {
    padding: 40px 15px;
  }

  .process-step {
    width: 90%;
    height: auto;
  }
}

@media (width <= 576px) {
  .hero {
    height: 40vh;
    padding-top: 60px;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .cta {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem;
  }

  .homehomeportfolio-item {
    width: 100%;
  }

  .testimonials {
    padding: 3rem 1rem;
  }

  .testimonials h2 {
    font-size: 2rem;
  }

  .testimonial-items {
    display: grid;
    grid-template-columns: 1fr;
  }

  .cta-section {
    padding: 2rem 1rem;
  }

  .cta-section h2 {
    font-size: 1.6rem;
  }

  .cta-section .cta {
    font-size: 1rem;
    padding: 0.7rem 1.5rem;
  }
}