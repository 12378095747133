/* Hero Section */
.portfolio-hero-section {
  position: relative;
  background: url("../../assets/Hero.jpg") no-repeat center center / cover;
  min-height: 60vh; /* Use min-height to avoid hiding content */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  width: 100%;
  padding: 0 20px; /* Add padding to prevent content from being cut off */
}

.portfolio-hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
}

.portfolio-hero-content {
  z-index: 2;
}

.portfolio-hero-content p {
  font-size: 1.6rem;
  margin: 1.5rem 0;
  color: rgb(255 255 255 / 85%);
  line-height: 1.8;
  letter-spacing: 1px;
  text-shadow: 1px 2px 5px rgb(0 0 0 / 30%);
  transition: all 0.3s ease-in-out;
}

.portfolio-hero-content h1 {
  font-size: 4rem;
  font-weight: bold;
  background: #fff;
  background-clip: text;
  letter-spacing: 2px;
  text-shadow: 2px 4px 10px rgb(0 0 0 / 30%);
  transition: all 0.3s ease-in-out;
}

/* Project Grid */
/* General Section Styles */
.completed-projects-section,
.in-progress-projects-section {
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.in-progress-projects-section h2,
.completed-projects-section h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
}

.portfolio-project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  padding: 0 20px;
}

.portfolio-project-card {
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 1;
}

.portfolio-project-image {
  width: 100%;
  height: auto;
}

.portfolio-project-info {
  padding: 20px;
  text-align: center;
  position: relative;
  z-index: 2;
}

.portfolio-project-info h3 {
  font-size: 1.6rem;
  color: #333;
  margin-bottom: 10px;
  position: relative;
}

.portfolio-project-info h3::before {
  content: "";
  width: 0;
  height: 4px;
  background: #ff7e5f;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transition: width 0.4s ease, left 0.4s ease;
}

.portfolio-project-info p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
}

.portfolio-project-link {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border-radius: 30px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Mobile Responsiveness */
@media (width <= 768px) {
  .portfolio-project-grid {
    grid-template-columns: 1fr;
  }

  .portfolio-project-info h3 {
    font-size: 1.4rem;
  }

  .portfolio-project-info p {
    font-size: 0.9rem;
  }

  .portfolio-project-link {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

/* Additional Mobile Optimizations */
@media (width <= 576px) {
  .portfolio-project-info h3 {
    font-size: 1.3rem;
  }

  .portfolio-project-info p {
    font-size: 0.85rem;
  }

  .portfolio-project-link {
    padding: 7px 14px;
    font-size: 0.85rem;
  }
}

/* Call to Action Section */
.portfolio-cta-section {
  text-align: center;
  padding: 50px 20px;
  background-color: #f4f4f4;
}

.portfolio-cta-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.portfolio-cta-section p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.portfolio-cta-button {
  padding: 1rem 2.5rem;
  background: linear-gradient(90deg, #007bff, #00c6ff);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgb(0 123 255 / 30%);
  transition: all 0.3s ease-in-out;
}

/* Full width */
.portfolio-page-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

/* Responsive Design Adjustments */
@media (width <= 992px) {
  .portfolio-hero-section h1 {
    font-size: 3rem;
  }

  .portfolio-cta-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
  }
}

@media (width <= 768px) {
  .portfolio-hero-section {
    height: 45vh;
  }

  .portfolio-hero-section h1 {
    font-size: 2.5rem;
  }
}

@media (width <= 576px) {
  .portfolio-hero-section {
    height: 40vh;
  }

  .portfolio-hero-section h1 {
    font-size: 2rem;
  }
}