/* Careers Page Container */
.careers-page-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    position: relative;
  }
  
  /* Hero Section */
  .careers-hero-section-container {
    text-align: center;
    padding: 50px;
    background-color: #282c34;
    color: #fff;
  }
  
  .careers-hero-title {
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .careers-hero-description {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .careers-hero-explore-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .careers-hero-explore-button:hover {
    background-color: #21a1f1;
  }
  
  /* Career Info Section */
  .careers-info-section-container {
    margin: 30px 0;
    text-align: center;
  }
  
  .careers-info-title {
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .careers-info-description {
    font-size: 18px;
    color: #555;
  }
  
  /* Job Listings */
  .careers-job-listings-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .careers-job-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease;
  }
  
  .careers-job-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .careers-job-image {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
  
  .careers-job-title {
    font-size: 24px;
  }
  
  .careers-job-description,
  .careers-job-salary,
  .careers-job-experience,
  .careers-job-education,
  .careers-job-skills {
    margin-bottom: 10px;
    font-size: 16px;
    color: #333;
  }
  
  .careers-job-apply-button {
    background-color: #61dafb;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .careers-job-apply-button:hover {
    background-color: #21a1f1;
  }
  
/* Modal */
.careers-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .careers-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-height: 90vh; /* Ensure the modal doesn't exceed the viewport height */
    overflow-y: auto; /* Add scroll if content overflows */
    position: relative;
    z-index: 10000;
  }
  
  .careers-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    z-index: 10001;
  }
  
  .careers-modal-title {
    margin-bottom: 20px;
    font-size: 22px;
    text-align: center;
  }
  
  /* Apply Form */
  .careers-apply-form {
    display: flex;
    flex-direction: column;
  }
  
  .careers-form-label {
    margin-bottom: 15px;
  }
  
  .careers-form-label input,
  .careers-form-label select {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .careers-form-submit-button {
    background-color: #61dafb;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }
  
  .careers-form-submit-button:hover {
    background-color: #21a1f1;
  }
  
  /* Responsive Modal Adjustments */
  @media (max-width: 768px) {
    .careers-modal-content {
      width: 90%; /* Adapt the modal width for smaller screens */
      max-height: 80vh; /* Allow more height for the modal on smaller screens */
    }
  }
  
  .careers-success-message {
    text-align: center;
    padding: 20px;
  }
  
  .careers-success-close-button {
    background-color: #61dafb;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  
  /* Apply Form */
  .careers-apply-form {
    display: flex;
    flex-direction: column;
  }
  
  .careers-form-label {
    margin-bottom: 10px;
  }
  
  .careers-form-submit-button {
    background-color: #61dafb;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .careers-form-submit-button:hover {
    background-color: #21a1f1;
  }
  
  /* Success Message */
  .careers-success-message {
    text-align: center;
    padding: 20px;
  }
  
  .careers-success-close-button {
    background-color: #61dafb;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  