/* Ensure no horizontal overflow */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure full width but prevent overflow */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Main Content Area */
.content {
  flex: 1;
  padding: 2rem;
  width: 100%; /* Use 100% width instead of viewport width */
  box-sizing: border-box;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .content {
    margin-left: 0;
    padding: 1rem;
    width: 100%; /* Ensure full width on smaller screens */
  }
}
/* General Page Structure */
.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Content Area */
.content {
  flex: 1;
  padding: 2rem 1.5rem; /* Padding between pages and the sides of the screen */
  margin-top: 20px; /* Space between the header and the page content */
  box-sizing: border-box;
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
  .content {
    padding: 1.5rem 1rem; /* Reduced padding for smaller screens */
    margin-top: 15px; /* Slightly reduced margin for mobile */
  }
}

@media (max-width: 480px) {
  .content {
    padding: 1rem 0.5rem; /* Even smaller padding for very small screens */
    margin-top: 10px; /* Reduced margin for smaller screens */
  }
}
